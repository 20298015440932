<template>
  <div class="landing-wrapper">
    <t-header />
    <div class="container space-2">
      <h2 class="text-center mb-5">Contact Us</h2>

      <div class="w-lg-80 mx-auto">
        <validation-observer ref="validator">
          <text-input v-model="contact.name" validation-rules="required">
            <template v-slot:label>Your name </template>
          </text-input>

          <div class="w-100 mb-3" />

          <text-input v-model="contact.email" validation-rules="required|email">
            <template v-slot:label>Your email </template>
          </text-input>

          <div class="w-100 mb-3" />
          <text-area v-model="contact.message" validation-rules="required">
            <template v-slot:label>
              What can we help you with?
            </template>
          </text-area>
        </validation-observer>
        <vue-recaptcha
          class="g-recaptcha mt-3"
          ref="recaptcha"
          @verify="
            () => {
              captchaVerified = true;
            }
          "
          @expired="
            () => {
              captchaVerified = false;
            }
          "
          sitekey="6Ldv4NEZAAAAAJJ9fIJXbyk7KY3XPrMOH9biWU_r"
        />
        <div class="w-100 mb-3" />
        <error-card class="mb-3" :errors="errors" />
        <div class="text-center">
          <btn block @click="onSubmit" :loading="isSending">
            Send
          </btn>

          <p class="text-success mt-1 pt-1" v-if="success">
            Thanks for contacting us! We'll get back to you as soon as we can.
          </p>

          <p class="small mt-4">
            We are doing our best to reply within 48 hours, excluding weekends
            and holidays.<br />
            If you have an urgent inquiry, please email us directly at
            support@thriftax.com
          </p>
        </div>
      </div>
    </div>
    <t-footer />
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { unauthClient } from "@/api";

export default {
  components: { VueRecaptcha },
  data: function() {
    return {
      contact: {
        name: "",
        email: "",
        message: ""
      },
      isSending: false,
      success: false,
      errors: [],
      captchaVerified: false
    };
  },
  methods: {
    clearForm() {
      for (let field in this.contact) {
        this.contact[field] = "";
      }
    },
    async onSubmit() {
      await this.$refs.validator.validate();
      if (!this.$refs.validator.flags.valid) return;
      this.errors = [];
      if (!this.captchaVerified) {
        this.errors.push("Please verify the captcha before submitting");
        return;
      }
      this.$refs.recaptcha.reset();
      this.captchaVerified = false;
      this.isSending = true;
      let form = new FormData();
      for (let field in this.contact) {
        form.append(field, this.contact[field]);
      }
      try {
        await unauthClient.post("contactus/", form);
        this.clearForm();
        this.success = true;
        var self = this;
        setTimeout(() => {
          self.success = false;
        }, 5000);
        this.$refs.validator.reset();
      } catch (e) {
        console.error(e.response);
        this.errors.push(e.response.data.substring(0, 110));
      }
      this.isSending = false;
    }
  }
};
</script>
